// @ts-nocheck
const gcd = (a, b) => {
  return b ? gcd(b, a % b) : a;
};
const decimalToFraction = (decimal) => {
  if (decimal == parseInt(decimal)) {
    return {
      top: parseInt(decimal),
      bottom: 1,
      display: parseInt(decimal) + "/" + 1,
    };
  } else {
    let top = decimal.toString().includes(".")
      ? decimal.toString().replace(/\d+[.]/, "")
      : 0;
    let bottom = Math.pow(10, top.toString().replace("-", "").length);
    if (decimal >= 1) {
      top = +top + Math.floor(decimal) * bottom;
    } else if (decimal <= -1) {
      top = +top + Math.ceil(decimal) * bottom;
    }

    const x = Math.abs(gcd(top, bottom));
    return {
      top: top / x,
      bottom: bottom / x,
      display: top / x + "/" + bottom / x,
    };
  }
};

/**
 * Helper method to convert number to a fractional string, styled using the ".fraction" class
 * @param n Number in inches
 * @param options Options for manipulating the output ( convertToMetric: boolean )
 * @returns Number converted to a fractional string ( eg. 1.25 returns "1 1/4" )
 */
const fractionString = (
  n: number = 0,
  options = { unit: "imperial" }
): string => {
  // ? This was dumb, make it a parameter
  // const { measurementUnit } = useContext(SiteContext);

  if (options.unit === "metric")
    return " " + (n * 2.54).toFixed(2).toString() + " cm";
  if (n === 0) return "";
  if (!n.toString().includes(".")) return " " + n.toString() + '"';

  let [a, b] = n.toString().split(".");
  b = Number("." + b);

  if (a == "0") {
    return " " + decimalToFraction(b).display + '"';
  }
  return " " + a + " " + decimalToFraction(b).display + '"';
};

/**
 * Helper method to get the decimal value of a number as a fraction string
 * @param n Number in inches
 * @returns fraction string for the decimal value of the number ( eg. 1.25 returns "1/4" )
 */
export const decimalString = (n: number): string => {
  if (n == undefined) return "";
  if (!n.toString().includes(".")) return "";
  const decimal = Number("." + n.toString().split(".")[1]);
  return decimalToFraction(decimal).display;
};

/**
 * Helper function to get value of a number split into integer and fractional unit
 * @param n Number in inches
 * @returns array of [integer, fractional value]
 */

export const splitNum = (n: number): [number, number] => {
  if (n == undefined) return [0, 0];
  return [Math.trunc(n), n % 1];
};

export const fractionObjSixteenths = {
  0: "",
  0.0625: <span>1/16</span>,
  0.125: <span>1/8</span>,
  0.1875: <span>3/16</span>,
  0.25: <span>1/4</span>,
  0.3125: <span>5/16</span>,
  0.375: <span>3/8</span>,
  0.4375: <span>7/16</span>,
  0.5: <span>1/2</span>,
  0.5625: <span>9/16</span>,
  0.625: <span>5/8</span>,
  0.6875: <span>11/16</span>,
  0.75: <span>3/4</span>,
  0.8125: <span>13/16</span>,
  0.875: <span>7/8</span>,
  0.9375: <span>15/16</span>,
};

export const sixteenthsList = [
  0, 0.0625, 0.125, 0.1875, 0.25, 0.3125, 0.375, 0.4375, 0.5, 0.5625, 0.625,
  0.6875, 0.75, 0.8125, 0.875, 0.9375,
];

export const fracStringDictionary : Record<string,number> = {
  "": 0,
  "1/16": 0.0625,
  "1/8": 0.125,
  "3/16": 0.1875,
  "1/4": 0.25,
  "5/16": 0.3125,
  "3/8": 0.375,
  "7/16": 0.4375,
  "1/2": 0.5,
  "9/16": 0.5625,
  "5/8": 0.625,
  "11/16": 0.6875,
  "3/4": 0.75,
  "13/16": 0.8125,
  "7/8": 0.875,
  "15/16": 0.9375,
};

export const mergeAsNum = (
  wholeNum: number,
  fracString: string = ""
): number => {
  return wholeNum + fracStringDictionary[fracString];
};

export default fractionString;
