import { Component } from "solid-js";
import { Img } from "../image";
import { ImageProps } from "@unpic/solid";

type ProductImageProps = ImageProps & {
  quality: number;
  src: string;
};

export const ProductImage: Component<ProductImageProps> = (props) => {
  let imageFallbackAttempted = false;

  const source = () =>
    `https://images.romamoulding.com/cdn-cgi/image/${
      props.quality ? `quality=${props.quality},` : ""
    }f=webp/${props.src}`;

  const setFallbackImage = (e: Event) => {
    if (!imageFallbackAttempted) {
      imageFallbackAttempted = true;
      const el = e.currentTarget as HTMLImageElement;

      el.src = "https://cdn.romamoulding.com/coming_soon.png";
      el.srcset = "https://cdn.romamoulding.com/coming_soon.png";

      const customEvent = new Event("imageLoadError", { bubbles: true });
      el.dispatchEvent(customEvent);
    }
  };

  return (
    <Img
      {...props}
      src={source()}
      cdn="cloudflare"
      onError={setFallbackImage}
    />
  );
};
