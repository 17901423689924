import {
  type Component,
  Show,
  Switch,
  Match,
  onMount,
  onCleanup,
} from "solid-js";

import { A } from "@solidjs/router";
import { EventType } from "@solid-primitives/analytics";
import { heart } from "solid-heroicons/outline";
import { heart as heartSolid } from "solid-heroicons/solid";
import { Icon } from "solid-heroicons";

import fractionString from "~/utils/fraction";
import { cloudflareImage } from "~/utils/cloudflare";
import {
  MOULDING,
  PHOTOFRAME,
  GALLERYFRAME,
  MIRROR,
  CONTRACT,
} from "~/utils/products";
import { ProductImage } from "./ProductImage";
import { trackFBQ } from "~/utils/analytics";
import { createAsync } from "@solidjs/router";
import { getFavourites } from "~/services/favourites";

import { useSiteContext, useSessionContext } from "~/utils/contexts";
// TODO - import and use PT/ProductType enum, use in place of props.type....
import { PT } from "~/utils/products";
import { FavouriteTypeOption } from "~/services/roma-api/favourites/types";

// TODO - this whole ProductModel needs a review..
export type ProductModel = {
  sku: string;
  width: number;
  height: number;
  rabbet: number;
  depth?: number;
  face?: number;
  price?: number;
  colour: string;
  hover: boolean;
  onLoad?: (evt: HTMLAnchorElement) => void;
  onCleanup?: (evt: HTMLAnchorElement) => void;
  collection: string;
  profile?: string;
  is_new: boolean;
  is_discontinued: boolean;
  is_discontinuing: boolean;
  is_coming_soon?: boolean;
  is_on_sale?: boolean;
  on_sale_note?: string;
  arrival_date?: string;
  addSampleToCart?: boolean;
  type: "moulding" | "photoFrame" | "mirror" | "galleryFrame" | "contract";
  // | "Fillet"
  // | "Stretcher";
  category?: string;
  image1: string;
  image2: string;
};

export const pathMapping: Record<string, string> = {
  [PHOTOFRAME]: "/photo-frame",
  [GALLERYFRAME]: "/gallery-frame",
  [MIRROR]: "/mirror",
  [MOULDING]: "",
};

// ! USE PSEUDO CLASS FOR HOVER IMAGE

export const Product: Component<
  {
    class?: string;
    classList?: object;
    loading?: "eager" | "lazy" | undefined;
    showPrice?: boolean;
    onClick?: VoidFunction;
  } & ProductModel
> = (props) => {
  let anchorRef: HTMLAnchorElement;
  const { session, toggleFav, isFavourited, isPartner } = useSessionContext();
  const { track } = useSiteContext();

  const favourite = () => isFavourited(props.sku, props.type as FavouriteTypeOption);

  const hoverImageUrl = props.hover
    ? cloudflareImage({
        src: props.image2,
        width: 440,
        height: 440,
        quality: 80,
        format: "webp",
        fit: "cover",
      })
    : undefined;
  // Only onloadmount if defined
  if (props.onLoad) {
    onMount(() => props.onLoad!(anchorRef));
  }

  if (props.onCleanup) {
    onCleanup(() => props.onCleanup!(anchorRef));
  }

  return (
    <div
      class="flex flex-col col-span-2 group"
      classList={{ [`${props.class}`]: !!props.class, ...props.classList }}
    >
      <A
        ref={(el) => (anchorRef = el)}
        href={`/product/${props.sku}${
          props.type == CONTRACT ? `?type=${CONTRACT}` : ""
        }${pathMapping[props.type!] || ""}`}
        data-hover-url={hoverImageUrl}
        class="w-full group aspect-square bg-roma-grey border border-transparent hover:border-gray-200 flex items-center justify-center relative"
        onClick={props.onClick}
      >
        <div
          class="w-full aspect-square flex items-center justify-center "
          classList={{
            "absolute inset-0 group-hover:opacity-0 transition-opacity duration-200 group-hover:-z-10":
              !!props.hover,
            "p-0": props.type == GALLERYFRAME,
            "p-8": props.type !== GALLERYFRAME,
          }}
          // @ts-expect-error
          // ? Custom event emitted by ProductImage to swap in a 'Coming Soon' image.
          on:imageLoadError={(e: Event): void => {
            (e.currentTarget as HTMLDivElement).style.padding = "0";
            e.stopPropagation();
          }}
        >
          <ProductImage
            src={props.image1}
            layout="fullWidth"
            aspectRatio={1}
            quality={85}
            breakpoints={[300, 440]}
            alt={`SKU #${props.sku}`}
          />
        </div>
        <div class="absolute top-0 left-0 m-2 z-10">
          <Switch>
            <Match when={props.is_on_sale}>
              <div class="flex items-center text-xs font-medium text-red-500 px-3 border border-red-500 bg-roma-grey rounded-full group-hover:text-white group-hover:bg-red-500 transition-colors">
                <p>On Sale</p>
              </div>
            </Match>
            <Match when={props.is_coming_soon}>
              <div class="flex items-center text-xs font-medium text-roma-blue px-3 border border-roma-blue bg-roma-grey rounded-full group-hover:text-white group-hover:bg-roma-blue transition-colors">
                <p>Coming {props.arrival_date ?? "Soon"}</p>
              </div>
            </Match>
            <Match when={props.is_new}>
              <div class="flex items-center text-xs font-medium text-roma-blue px-3 border border-roma-blue bg-roma-grey rounded-full group-hover:text-white group-hover:bg-roma-blue transition-colors">
                <p>New</p>
              </div>
            </Match>
            <Match when={props.is_discontinuing}>
              <div class="flex items-center text-xs font-medium px-3 border border-orange-300 bg-white rounded-full text-orange-300 group-hover:text-white group-hover:bg-orange-300 transition-colors">
                <p>Discontinuing</p>
              </div>
            </Match>
            <Match when={props.is_discontinued}>
              <div class="flex items-center text-xs font-medium px-3 border border-orange-300 bg-white rounded-full text-orange-300 group-hover:text-white group-hover:bg-orange-300 transition-colors">
                <p>Discontinued</p>
              </div>
            </Match>
          </Switch>
        </div>
      </A>
      <div class="flex justify-between mt-5">
        <p class="font-bold text-base tracking-wide">{props.sku}</p>
        <Show when={isPartner()}>
          <button
            class="pr-1"
            aria-label={
              favourite()
                ? `Remove product ${props.sku} from favourites`
                : `Add product ${props.sku} to favourites`
            }
            onClick={(evt: Event) => {
              evt.preventDefault();
              // only register tracking event if they are adding
              if (!favourite()) {
                // TODO 
                // track(EventType.Event, {
                //   category: "favourite",
                //   action: "selected",
                //   label: props.sku,
                //   value: props.type,
                // });
                // trackFBQ("AddToWishlist", {
                //   content_name: `${props.sku} - ${props.collection}, ${props.colour}, ${props.type}`,
                // });
              }
              toggleFav(props.sku, props.type as FavouriteTypeOption);
            }}
          >
            <Show
              fallback={
                <Icon path={heart} class="w-6 cursor-pointer text-roma-blue" />
              }
              when={favourite()}
            >
              <Icon
                path={heartSolid}
                class="w-6 cursor-pointer text-roma-blue"
              />
            </Show>
          </button>
        </Show>
      </div>
      <p class="text-roma-medium-grey text-base font-normal">
        {props.collection}, {props.colour}
      </p>
      <div class="flex gap-2 text-roma-medium-grey fraction font-medium text-sm mt-1 flex-wrap">
        <Show when={props.width}>
          <span class="whitespace-nowrap">W{fractionString(props.width)}</span>
        </Show>
        <Show when={props.height}>
          <span class="whitespace-nowrap">H{fractionString(props.height)}</span>
        </Show>
        <Show when={props.rabbet && props.rabbet > 0}>
          <span class="whitespace-nowrap">R{fractionString(props.rabbet)}</span>
        </Show>
        <Show when={props.depth}>
          <span class="whitespace-nowrap">D{fractionString(props.depth)}</span>
        </Show>
        <Show when={props.face}>
          <span class="whitespace-nowrap">F{fractionString(props.face)}</span>
        </Show>
      </div>
      <Show when={props.showPrice}>
        <p class="text-base mt-2 tracking-wide">${props.price!.toFixed(2)}</p>
      </Show>
    </div>
  );
};
