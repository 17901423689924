import { FlowComponent, JSXElement, Show } from "solid-js";
import { chevronDown } from "solid-heroicons/outline";
import { plus } from "solid-heroicons/outline";
import { Icon } from "solid-heroicons";

const icons = {
  Chevron: chevronDown,
  Plus: plus,
};

type AccordionFields = {
  name: string;
  label: string | JSXElement;
  labelClass?: string;
  icon?: "Chevron" | "Plus";
  iconRotation?: "45" | "90" | "180";
  iconPosition?: "Left";
  class?: string;
  children: any;
  childClass?: string;
  checked?: boolean;
  contentHeight?: "Large";
  fadeBottom?: boolean;
  footer?: string | JSXElement;
  onOpen?: (val: boolean)=>void;
};

export const Accordion: FlowComponent<AccordionFields> = (props: AccordionFields) => (
  <div classList={{ [`${props.class}`]: !!props.class }}>
    <input
      type="checkbox"
      id={props.name}
      class="sr-only peer accordion-input"
      checked={props.checked}
      onChange={(e)=>{
        if (props.onOpen) {
          props.onOpen(e.currentTarget.checked)
        }
      }}
    />
    <label
      for={props.name}
      class="flex gap-3 accordion-label cursor-pointer items-center"
      classList={{
        [`${props.labelClass}`]: !!props.labelClass,
      }}
    >
      <Show when={props.icon && props.iconPosition === "Left"}>
        <Icon
          path={icons[props.icon!]}
          class={`w-4 ml-auto block transition-[transform] duration-200 accordion-icon-${
            props.iconRotation ? props.iconRotation : "180"
          }`}
        />
      </Show>
      <div class="w-full">{props.label}</div>
      <Show when={props.icon && props.iconPosition !== "Left"}>
        <Icon
          path={icons[props.icon!]}
          class={`w-4 ml-auto block transition-[transform] duration-200 accordion-icon-${
            props.iconRotation ? props.iconRotation : "180"
          }`}
        />
      </Show>
    </label>
    <Show
      when={props.contentHeight === "Large"}
      fallback={
        <div
          class="
        max-h-0
        peer-checked:max-h-[260px]
        peer-checked:my-4
        overflow-hidden
        transition-all
        duration-500
        relative
      "
          classList={{
            [`${props.childClass}`]: !!props.childClass,
            "overlay-vertical-fades": !!props.fadeBottom,
          }}
        >
          {props.children}
        </div>
      }
    >
      <div
        class="
          max-h-0
          peer-checked:max-h-[1000px]
          peer-checked:my-4
          overflow-y-auto
          transition-all
          duration-1000
        "
        classList={{
          [`${props.childClass}`]: !!props.childClass,
        }}
      >
        {props.children}
      </div>
    </Show>
    {props.footer}
  </div>
);

