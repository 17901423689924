/**
 * Helper method to query the content.
 * @param model Model type to retrieve
 * @param query Query values.
 * @returns Builder page content
 */
export const cloudflareImage = (obj: {
  src?: string;
  width?: number;
  height?: number;
  quality?: number;
  format?: string;
  fit?: "inside" | "fill" | "cover" | "outside" | "contain";
}): string => {
  const src = obj.src;
  delete obj.src;
  obj.format = "webp";
  obj.quality = obj.quality || 85;
  return (
    `${import.meta.env.VITE_IMAGE_PATH}?` +
    Object.entries({ ...obj, image: src })
      .map(([key, value]) => `${key}=${value}`)
      .join("&")
  );
};
