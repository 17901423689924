// import { type Component, type FlowComponent, Show, createMemo } from "solid-js";
import type { FlowComponent, Component, JSX } from "solid-js";
import { Show } from "solid-js";
import { Icon } from "solid-heroicons";
import { chevronLeft, chevronRight } from "solid-heroicons/solid";
import { debounce } from "@solid-primitives/scheduled";

export const Carousel: FlowComponent<{
  title?: string;
  href?: string;
  hrefLabel?: string;
  size?: "small";
  buttonLocation?: "top" | "bottom";
  rootClass?: string;
  containerClass?: string;
  buttonContainerClass?: string;
  buttonStyle?: "light" | "dark";
  disableFade?: boolean;
}> = (props) => {
  let containerRef: HTMLElement;
  let parentRef: HTMLElement;
  const scrollInactive = debounce(
    () => parentRef.classList.remove("active"),
    200
  );
  
  const scrollDirection = (direction = 1) => {
    return () => {
      let position = 0,
        index = 0;
      const arr = [];
      const childs = containerRef.childNodes;
      for (const el of childs) {
        arr.push((el as HTMLElement).offsetLeft);
        if ((el as HTMLElement).offsetLeft <= containerRef.scrollLeft) {
          position = index;
        }
        index += 1;
      }
      position += direction;
      const container = containerRef.getBoundingClientRect();
      const bounds = (childs[childs.length - 1] as HTMLElement).getBoundingClientRect();
      if (direction == 1 && bounds.x <= container.width - container.x) {
        position = 0;
      } else if (position < 0) {
        position = arr.length - 1;
      } else if (position >= arr.length - 1) {
        position = 0;
      }
      containerRef.scrollTo({
        left: arr[position],
        behavior: "smooth",
      });
    };
  };

  const DirectionButton: Component<{
    icon: {
      path: JSX.Element;
      outline?: boolean;
      mini?: boolean;
    };
    direction: number;
    size?: "small";
    buttonStyle?: "light" | "dark";
  }> = (props) => {
    return (
      <button
        aria-label={props.direction > 1 ? "Scroll left" : "Scroll right"}
        onClick={scrollDirection(props.direction)}
        class={`hidden xs:flex transition-colors items-center justify-center rounded-full  ${
          props.size === "small" ? "w-8 h-8 p-1" : "h-10 w-10 p-2"
        }   duration-200 text-roma-dark-grey ${
          props.buttonStyle === "dark"
            ? "bg-gray-200 hover:bg-roma-medium-grey hover:text-white"
            : "border border-gray-100 hover:border-roma-blue"
        } `}
      >
        <Icon
          class={`${props.size === "small" ? "h-3.5 w-3.5" : "h-5 w-5"}`}
          path={props.icon}
          classList={{
            "-ml-0.5": props.direction === -1,
            "-mr-0.5": props.direction === 1,
          }}
        />
      </button>
    );
  };

  return (
    <div
      class="flex flex-col space-y-5"
      classList={{ [`${props.rootClass}`]: !!props.rootClass }}
    >
      <div class="flex justify-between items-end sm:items-center sm:px-2">
        <Show when={props.title}>
          <h3
            class={`${
              props.size === "small"
                ? "text-base sm:text-lg"
                : "text-lg sm:text-xl"
            } font-semibold`}
          >
            {props.title}
          </h3>
        </Show>
        <div
          class="flex ml-auto items-center space-x-3"
          classList={{
            [`${props.buttonContainerClass}`]: !!props.buttonContainerClass,
          }}
        >
          <Show when={props.hrefLabel}>
            <a
              href={props.href}
              class="text-roma-blue text-sm sm:text-md whitespace-nowrap"
            >
              {props.hrefLabel}
            </a>
          </Show>
          <Show when={props.buttonLocation !== "bottom"}>
            <DirectionButton
              icon={chevronLeft}
              direction={-1}
              size={props.size}
              buttonStyle={props.buttonStyle}
            />
            <DirectionButton
              icon={chevronRight}
              direction={1}
              size={props.size}
              buttonStyle={props.buttonStyle}
            />
          </Show>
        </div>
      </div>
      <div
        ref={(ref) => (parentRef = ref)}
        class={`relative overflow-hidden ${
          props.disableFade ? "" : "overlay-horizontal-fades"
        }`}
      >
        <div
          ref={(ref) => (containerRef = ref)}
          class="w-full flex snap-x overflow-x-auto pb-4"
          classList={{ [`${props.containerClass}`]: !!props.containerClass }}
          onScroll={() => {
            parentRef.classList.add("active");
            scrollInactive();
          }}
        >
          {props.children}
        </div>
      </div>
      <Show when={props.buttonLocation === "bottom"}>
        <div
          class="ml-auto flex items-center gap-3"
          classList={{
            [`${props.buttonContainerClass}`]: !!props.buttonContainerClass,
          }}
        >
          <DirectionButton
            icon={chevronLeft}
            direction={-1}
            size={props.size}
            buttonStyle={props.buttonStyle}
          />
          <DirectionButton
            icon={chevronRight}
            direction={1}
            size={props.size}
            buttonStyle={props.buttonStyle}
          />
        </div>
      </Show>
    </div>
  );
};
